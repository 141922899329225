<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep';
import ProductType from '~/components/store/productType/productType.vue';
import LoadingScreen from '@/components/global/LoadingScreen.vue';
import type { ProductCategory } from '~/types/ProductCategory';
import SearchBar from '~/components/global/SearchBar.vue';
import DialogLoading from '~/components/ui/dialog/DialogLoading.vue';
import debounce from 'lodash/debounce';

// LAZY LOAD
const CreateProductModal = defineAsyncComponent(() => import('~/components/store/productType/createProductTypeModal.vue'));
const DeleteProductTypeModal = defineAsyncComponent(() => import('~/components/store/productType/deleteProductTypeModal.vue'));
const EditProductTypeModal = defineAsyncComponent(() => import('~/components/store/productType/editProductTypeModal.vue'));

definePageMeta({
    middleware: ['manager', 'store'],
});

// VARIABLE
const productCategoryService = useProductCategory();
const searchText = ref<string>('');
const productCategories = ref<ProductCategory[]>([]);
const activeProductCateType = ref<ProductCategory | null>(null);
const activeProductCate = ref<ProductCategory>({} as ProductCategory);
const pending = ref<boolean>(true);
const { $toast } = useNuxtApp();
const createModalOpenState = ref<boolean>(false);
const updateModalOpenState = ref<boolean>(false);
const deleteModalOpenState = ref<boolean>(false);

// FUNCTION
async function getProductCategories(): Promise<ProductCategory[]> {
    const data = await productCategoryService.getAll(
        {
            search: searchText.value,
            orderBy: {
                createdAt: 'desc',
            },
        },
        {
            errorCallback: () => {
                $toast.error('เกิดปัญหาระหว่างดึงข้อมูลประเภทสินค้า');
            },
        },
    );

    return data;
}

function deleteProductCateType(productCate: ProductCategory) {
    activeProductCate.value = productCate;
    deleteModalOpenState.value = true;
}

function editProductCateType(productCate: ProductCategory) {
    activeProductCate.value = cloneDeep(productCate);
    updateModalOpenState.value = true;
}

async function refreshProductCategory() {
    pending.value = true;
    productCategories.value = await getProductCategories();
    pending.value = false;
}

const searchProductCategory = debounce(async () => {
    productCategories.value = await getProductCategories();
}, 250);

// ONMOUNTED
onMounted(async () => {
    productCategories.value = await getProductCategories();
    pending.value = false;
});
</script>
<template>
    <div>
        <NuxtLayout page-name="ประเภทสินค้า">
            <template #topbar>
                <div class="flex gap-5 h-full">
                    <SearchBar v-model="searchText" placeholder="ค้นหาด้วยชื่อประเภท" class="grow h-full" @search="searchProductCategory" />
                    <Button type="button" class="text-lg h-full" @click="createModalOpenState = true">
                        <IconCSS name="material-symbols:add-rounded" class="mr-1" size="2rem" />
                        เพิ่มประเภทสินค้า
                    </Button>
                </div>
            </template>
            <div v-if="!pending">
                <ProductType
                    v-model="activeProductCateType"
                    :data="productCategories"
                    @delete="(productCate) => deleteProductCateType(productCate)"
                    @edit="(productCate) => editProductCateType(productCate)"
                />

                <Suspense v-if="deleteModalOpenState">
                    <DeleteProductTypeModal v-model:open="deleteModalOpenState" :category-id="activeProductCate.id" @refresh="refreshProductCategory()" />
                    <template #fallback>
                        <DialogLoading />
                    </template>
                </Suspense>

                <Suspense v-if="updateModalOpenState">
                    <EditProductTypeModal v-model:open="updateModalOpenState" :category-id="activeProductCate.id" @refresh="refreshProductCategory()" />
                    <template #fallback>
                        <DialogLoading />
                    </template>
                </Suspense>

                <Suspense v-if="createModalOpenState">
                    <CreateProductModal v-model:open="createModalOpenState" @refresh="refreshProductCategory()" />
                    <template #fallback>
                        <DialogLoading />
                    </template>
                </Suspense>
            </div>
            <LoadingScreen v-else />
        </NuxtLayout>
    </div>
</template>
